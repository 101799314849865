import React from 'react';
import '../styles/App.css';

function App() {
  return (
    <div className="App">
      <h1 className="title is-1">Coming Soon!</h1>
    </div>
  );
}

export default App;
